import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import git from '../img/github-icon.svg'
import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Roy Toren"
            style={{ width: '14em', height: '10em' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="footer-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/about">
                        About Me
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="footer-item" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/book-summaries">
                        Book Summaries
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="social">
                Follow me on
                <a title="git" href="https://github.com/RoyToren">
                  <img
                    src={git}
                    alt="Git"
                    style={{ width: '1em', height: '1em', display: 'inline' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/RoyToren">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em', display: 'inline' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          @2020 By Roy Toren
        </div>
      </footer>
    )
  }
}

export default Footer
